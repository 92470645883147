@use "../utils/mixins" as m;

.social-links-list {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
}

.social-link {
  --size: 44px;

  width: var(--size);
  height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  color: var(--second-text-color);
  @include m.transition(background-color);

  &:hover,
  &:focus {
    background-color: var(--hover-color);
  }
}

.social-link-icon {
  fill: currentColor;
}
