@use "../utils/mixins" as m;

.logo {
  position: relative;
  z-index: 6;
  display: inline-block;
  padding: 8px 6px;
  @include m.font(24px, 400, 29px);
  color: var(--second-text-color);
  border: 3px solid var(--second-text-color);

  border-radius: 12px 0;
}
