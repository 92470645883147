@use "sass:math";

@function decimal-round($number, $digits: 0, $mode: round) {
  $n: 1;
  // $number must be a number
  @if type-of($number) != number {
    @warn '#{ $number } is not a number.';
    @return $number;
  }
  // $digits must be a unitless number
  @if type-of($digits) != number {
    @warn '#{ $digits } is not a number.';
    @return $number;
  } @else if not unitless($digits) {
    @warn '#{ $digits } has a unit.';
    @return $number;
  }
  @for $i from 1 through $digits {
    $n: $n * 10;
  }
  @if $mode == round {
    @return calc(round($number * $n) / $n);
  } @else if $mode == ceil {
    @return ceil($number * $n) / $n;
  } @else if $mode == floor {
    @return floor($number * $n) / $n;
  } @else {
    @warn '#{ $mode } is undefined keyword.';
    @return $number;
  }
}

//rem
@function rem($pxValue) {
  @return math.div($pxValue, 16px) * 1rem;
}

@mixin font($fs, $fw, $lh) {
  font-size: rem($fs);
  font-weight: $fw;
  line-height: decimal-round(calc($lh/$fs), 2);
}

@mixin transition($property) {
  transition: all 0.3s ease-in-out;
  transition-property: $property;
}
