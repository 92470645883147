*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

:root {
  --primary-font: "Libre Franklin", sans-serif;
}

.visually-hidden {
  width: 1px;
  height: 1px;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.hidden {
  opacity: 0;
  filter: blur(4px);
  transition: all 1s ease-in-out;
}

.animation {
  opacity: 1;
  filter: blur();
}

@media screen and (min-width: 1200px) {
  .hidden-items {
    opacity: 0;
    filter: blur(4px);
    transition: all .8s ease-in-out;
    transform: translateX(-100%);
  }

  .animate-items {
    opacity: 1;
    filter: blur();
    transform: translateX(0);
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--primary-font);
  color: var(--primary-text-color);
}

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

button {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.spinner {
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border-top: 3px solid #fff;
  border-right: 3px solid #0000;
  border-radius: 50%;
  animation: 1s linear infinite rotation;
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner_hidden {
  width: 1px;
  height: 1px;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.section {
  padding-top: 64px;
  padding-bottom: 80px;
}

@media screen and (min-width: 991px) {
  .section {
    padding-top: 90px;
    padding-bottom: 140px;
  }
}

.container {
  width: min(85vw, 1000px);
  margin-inline: auto;
}

.logo {
  z-index: 6;
  color: var(--second-text-color);
  border: 3px solid var(--second-text-color);
  border-radius: 12px 0;
  padding: 8px 6px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.21;
  display: inline-block;
  position: relative;
}

.switcher {
  z-index: 5;
  border: none;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0;
  padding: 2px;
  display: grid;
  position: relative;
}

.switcher__radio {
  -webkit-appearance: none;
  appearance: none;
  width: 32px;
  height: 32px;
  opacity: .8;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  transition: filter .1s ease-in-out;
}

.switcher__radio:focus {
  outline: none;
}

.switcher__radio_light {
  background-image: url("light.b762eab7.svg");
}

.switcher__radio_auto {
  background-image: url("default.2d03e663.svg");
  background-size: 18px;
}

.switcher__radio_dark {
  background-image: url("dark.d5332de6.svg");
}

.switcher__status {
  z-index: -1;
  opacity: .7;
  background-image: url("status.3af589db.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 32px;
  border-radius: 18px;
  transition: background-position .1s ease-in-out;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 0 2px #0003;
}

.switcher__radio:focus-visible ~ .switcher__status {
  box-shadow: 0 0 0 2px #000;
}

.switcher__radio_light:checked ~ .switcher__status {
  background-position: 2px 50%;
}

.switcher__radio_autot:checked ~ .switcher__status {
  background-position: center;
}

.switcher__radio_dark:checked ~ .switcher__status {
  background-position: right 2px center;
}

@media screen and (min-width: 991px) {
  .mouse {
    width: 28px;
    height: 48px;
    box-sizing: border-box;
    border: 2px solid #fff;
    border-radius: 50% / 25%;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .mouse:before {
    content: "";
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
    animation: 1s ease-out infinite fadeDown;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

@keyframes fadeDown {
  0% {
    opacity: 1;
    top: 0%;
  }

  100% {
    opacity: 0;
    top: 60%;
  }
}

.title {
  margin-bottom: 2.8em;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.21;
}

@media screen and (min-width: 1200px) {
  .title {
    margin-bottom: 3.125em;
  }
}

.btn-menu__icon, .btn-menu:before, .btn-menu:after {
  width: 100%;
  height: 13%;
  background-color: var(--second-text-color);
  transition: all .3s;
  display: inline-block;
  position: absolute;
  left: 0;
}

.btn-menu {
  width: 30px;
  height: 18px;
  z-index: 5;
  background-color: #0000;
  border: 0;
  padding: 0;
  line-height: 0;
  display: block;
  position: relative;
}

.btn-menu:before, .btn-menu:after {
  content: "";
}

.btn-menu:before {
  top: 0;
}

.btn-menu:after {
  bottom: 0;
}

.btn-menu__icon {
  top: 50%;
  transform: translate(0, -50%)scale(1);
}

.btn-menu.active .btn-menu__icon {
  transform: scale(0);
}

.btn-menu.active:before {
  top: 50%;
  transform: rotate(45deg)translate(0, -50%);
}

.btn-menu.active:after {
  bottom: 50%;
  transform: rotate(-45deg)translate(0, 50%);
}

@media screen and (min-width: 768px) {
  .btn-menu {
    display: none;
  }
}

.btn {
  z-index: 1;
  color: var(--primary-text-color);
  background-color: var(--second-back-color);
  border-radius: 10px;
  transition: transform .3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.btn:after {
  content: "";
  z-index: -1;
  width: 1.25em;
  background-color: #fff3;
  position: absolute;
  top: -50%;
  bottom: -50%;
  transform: translate3d(-825%, 0, 0)rotate(35deg);
}

.btn:hover:after, .btn:focus:after {
  transition: transform .45s ease-in-out;
  transform: translate3d(450%, 0, 0)rotate(35deg);
}

.btn:hover, .btn:focus {
  transform: scale(1.05);
}

.social-links-list {
  justify-content: center;
  align-items: center;
  column-gap: .5rem;
  margin-bottom: .5rem;
  display: flex;
}

.social-link {
  --size: 44px;
  width: var(--size);
  height: var(--size);
  color: var(--second-text-color);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: background-color .3s ease-in-out;
  display: flex;
}

.social-link:hover, .social-link:focus {
  background-color: var(--hover-color);
}

.social-link-icon {
  fill: currentColor;
}

.scroll-top {
  width: 50px;
  height: 50px;
  color: var(--hover-color);
  background-color: var(--back-color);
  cursor: pointer;
  opacity: 0;
  border: none;
  border-radius: 50%;
  padding: 0;
  line-height: 0;
  transition: color .3s ease-in-out, background-color .3s ease-in-out, opacity .3s ease-in-out;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.scroll-top:hover {
  color: var(--hover-color);
  background-color: var(--second-back-color);
}

.scroll-top_active {
  opacity: 1;
}

.scroll-top__icon {
  width: 50px;
  height: 50px;
  fill: currentColor;
}

.header {
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: absolute;
  top: 0;
  left: 0;
}

.header-container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 767.98px) {
  .lock {
    overflow: hidden;
  }

  .header-menu {
    order: 2;
  }

  .nav {
    z-index: 4;
    width: 100%;
    height: 100%;
    background: var(--back-gradient);
    justify-content: center;
    padding: 140px 30px 30px;
    transition: left .3s ease-in-out;
    display: flex;
    position: fixed;
    top: 0;
    left: -100%;
    overflow: auto;
  }

  .nav.active {
    left: 0;
  }

  .nav-list-item:not(:last-child) {
    margin-bottom: 20px;
  }

  .nav-list-link {
    letter-spacing: 3px;
    color: var(--second-text-color);
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.17;
  }
}

@media screen and (min-width: 768px) {
  .nav {
    display: block;
  }

  .nav-list {
    gap: 2rem;
    display: flex;
  }

  .nav-list-link {
    color: var(--second-text-color);
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.22;
    position: relative;
  }

  .nav-list-link:before {
    content: "";
    width: 100%;
    height: 3px;
    background-color: var(--hover-color);
    transform-origin: center;
    transition: transform .3s ease-in-out;
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translate(-50%)scaleX(0);
  }

  .nav-list-link:hover:before, .nav-list-link:focus:before {
    transform: translate(-50%)scaleX(1);
  }
}

@media screen and (min-width: 991px) {
  .nav-list {
    gap: 3rem;
  }
}

.github-corner-icon {
  fill: #000;
  color: #fff;
  z-index: 9999;
  opacity: .85;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(-1, 1);
}

.github-corner:hover .octo-arm {
  animation: .56s ease-in-out octocat-wave;
}

@keyframes octocat-wave {
  0%, 100% {
    transform: rotate(0);
  }

  20%, 60% {
    transform: rotate(-25deg);
  }

  40%, 80% {
    transform: rotate(10deg);
  }
}

@media (max-width: 991px) {
  .github-corner {
    display: none;
  }
}

.hero {
  min-height: 100vh;
  color: var(--second-text-color);
  background: var(--back-gradient);
  padding-top: 120px;
  padding-bottom: 120px;
}

.hero-flex {
  padding-top: 2rem;
}

.hero-title {
  margin-bottom: .6em;
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 1.23;
}

.hero-subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.21;
}

.hero-flex-right {
  display: none;
}

@media screen and (min-width: 650px) {
  .hero-title {
    font-size: 4rem;
  }
}

@media screen and (min-width: 768px) {
  .hero-flex {
    align-items: center;
    display: flex;
  }

  .hero-flex-left {
    flex: 0 60%;
  }

  .hero-flex-right {
    flex: 0 40%;
    display: block;
  }
}

.about {
  background-color: var(--default-bg-color);
}

.about-description {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.67;
}

.about-description:not(:last-child) {
  margin-bottom: 1.89em;
}

.skills {
  background-color: var(--back-color);
}

.tech-list {
  flex-wrap: wrap;
  gap: 16px;
  display: flex;
}

.tech-items {
  background-color: var(--second-back-color);
  border-radius: 8px;
  flex-direction: column;
  flex: 0 90px;
  align-items: center;
  gap: 10px;
  padding: 10px 10px 5px;
  display: flex;
}

.tech-items span {
  font-size: .7rem;
}

.tech-items svg {
  fill: currentColor;
}

@media screen and (min-width: 1200px) {
  .tech-items:nth-child(2) {
    transition-delay: .2s;
  }

  .tech-items:nth-child(3) {
    transition-delay: .3s;
  }

  .tech-items:nth-child(4) {
    transition-delay: .4s;
  }

  .tech-items:nth-child(5) {
    transition-delay: .5s;
  }

  .tech-items:nth-child(6) {
    transition-delay: .6s;
  }

  .tech-items:nth-child(7) {
    transition-delay: .7s;
  }

  .tech-items:nth-child(8) {
    transition-delay: .8s;
  }

  .tech-items:nth-child(9) {
    transition-delay: .9s;
  }
}

.projects {
  background-color: var(--default-bg-color);
}

.projects-list-item {
  max-width: 480px;
  background-color: var(--back-color);
  border-radius: 10px;
  transition: all .3s ease-in-out;
  overflow: hidden;
  box-shadow: 0 4px 6px #0000004d;
}

.projects-list-item:hover {
  transform: scale(1.01);
  box-shadow: 0 10px 20px #000000b3;
}

.projects-list-item:not(:last-child) {
  margin-bottom: 50px;
}

.project {
  min-height: 100%;
  flex-direction: column;
  display: flex;
}

.project-img {
  aspect-ratio: 1.6;
}

.project-desc-wrap {
  flex-direction: column;
  flex: auto;
  padding: 2rem;
  display: flex;
}

.project-title {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.39;
}

.project-description {
  margin-bottom: .8rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.38;
}

.project-stack {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.38;
}

.project-stack span {
  font-weight: 600;
}

.project-list {
  justify-content: center;
  column-gap: 10px;
  margin-top: auto;
  display: flex;
}

.project-list-item {
  min-width: 85px;
}

.project-link {
  justify-content: center;
  align-items: center;
  padding: .6rem .4rem;
  font-size: .625rem;
  font-weight: 400;
  line-height: 1.2;
  display: flex;
}

.project-link svg {
  fill: currentColor;
  margin-right: .3rem;
}

@media screen and (min-width: 412px) {
  .project-list-item {
    min-width: 135px;
  }

  .project-link {
    font-size: .75rem;
  }
}

@media screen and (min-width: 480px) {
  .projects-list {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-auto-rows: 1fr;
    gap: 50px 30px;
    display: grid;
  }

  .projects-list-item:not(:last-child) {
    margin-bottom: 0;
  }

  .project-link {
    padding: .8rem .6rem;
    font-size: 1rem;
  }
}

.contact-form-message, .contact-form-input {
  width: 100%;
  height: 2.6rem;
  color: var(--primary-text-color);
  background-color: var(--back-color);
  border: 1.6px solid #21212133;
  border-radius: 4px;
  padding: .7rem .8rem;
  transition: border-color .3s ease-in-out;
}

.contact-form-message:focus, .contact-form-input:focus {
  border-color: var(--hover-color);
  outline: none;
}

.contact {
  background-color: var(--back-color);
  position: relative;
}

.contact-form {
  max-width: 480px;
  flex-flow: column wrap;
  display: flex;
}

.contact-form-wrapper {
  flex-direction: column;
  gap: 1.2rem;
  margin-bottom: 1.2rem;
  display: flex;
}

.contact-form-label {
  transition: color .3s ease-in-out;
}

.contact-form-label:focus-within {
  color: var(--hover-color);
}

.contact-form-name {
  margin-bottom: 6px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  display: block;
}

.input-wrapper {
  display: block;
  position: relative;
}

.contact-form-input {
  padding-left: 42px;
}

.contact-form-icon {
  width: 16px;
  height: 16px;
  fill: var(--primary-text-color);
  transition: fill .3s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translate(0, -50%);
}

.contact-form-input:focus + .contact-form-icon {
  fill: var(--hover-color);
}

.contact-form-message {
  min-height: 8rem;
  resize: none;
}

.form-btn {
  min-width: 155px;
  border: none;
  align-self: flex-start;
  margin-top: 1.2rem;
  padding: .8rem .6rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
}

@media screen and (min-width: 695px) {
  .contact-form-wrapper {
    flex-direction: row;
  }
}

.footer {
  color: var(--second-text-color);
  background: var(--back-gradient);
  padding-top: 30px;
  padding-bottom: 10px;
}

.footer-desc {
  text-align: center;
  font-size: .8125rem;
  font-weight: 400;
  line-height: 1.54;
}

/*# sourceMappingURL=index.18a401df.css.map */
