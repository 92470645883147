@use "../utils/mixins" as m;

%input {
  width: 100%;
  height: 2.6rem;
  padding: 0.7rem 0.8rem;
  border: 1.6px solid rgba(33, 33, 33, 0.2);
  color: var(--primary-text-color);
  background-color: var(--back-color);
  border-radius: 4px;

  @include m.transition(border-color);

  &:focus {
    outline: none;
    border-color: var(--hover-color);
  }
}

.contact {
  position: relative;
  background-color: var(--back-color);
}

.contact-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 480px;
}

.contact-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin-bottom: 1.2rem;
}

.contact-form-label {
  @include m.transition(color);

  &:focus-within {
    color: var(--hover-color);
  }
}

.contact-form-name {
  display: block;
  margin-bottom: 6px;
  @include m.font(16px, 400, 19.2px);
}

.input-wrapper {
  display: block;
  position: relative;
}

.contact-form-input {
  @extend %input;
  padding-left: 42px;
}

.contact-form-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translate(0, -50%);
  display: block;
  width: 16px;
  height: 16px;

  fill: var(--primary-text-color);

  @include m.transition(fill);

  .contact-form-input:focus + & {
    fill: var(--hover-color);
  }
}

.contact-form-message {
  @extend %input;
  min-height: 8rem;

  resize: none;
}

.form-btn {
  min-width: 155px;
  align-self: flex-start;
  padding: 0.8rem 0.6rem;
  margin-top: 1.2rem;
  @include m.font(16px, 400, 19.2px);

  border: none;
}

@media screen and (min-width: 695px) {
  .contact-form-wrapper {
    flex-direction: row;
  }
}
