@use "../utils/mixins" as m;

.footer {
  padding-top: 30px;
  padding-bottom: 10px;

  color: var(--second-text-color);
  background: var(--back-gradient);
}

.footer-desc {
  @include m.font(13px, 400, 20px);
  text-align: center;
}
