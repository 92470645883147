.skills {
  background-color: var(--back-color);
}

.tech-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.tech-items {
  flex: 0 1 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px 10px 5px;
  border-radius: 8px;
  background-color: var(--second-back-color);

  & span {
    font-size: 0.7rem;
  }

  & svg {
    fill: currentColor;
  }
}

@media screen and (min-width: 1200px) {
  .tech-items {
    &:nth-child(2) {
      transition-delay: 200ms;
    }
    &:nth-child(3) {
      transition-delay: 300ms;
    }
    &:nth-child(4) {
      transition-delay: 400ms;
    }
    &:nth-child(5) {
      transition-delay: 500ms;
    }
    &:nth-child(6) {
      transition-delay: 600ms;
    }
    &:nth-child(7) {
      transition-delay: 700ms;
    }
    &:nth-child(8) {
      transition-delay: 800ms;
    }
    &:nth-child(9) {
      transition-delay: 900ms;
    }
  }
}
