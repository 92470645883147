html {
  scroll-behavior: smooth;
}

body {
  // height: 100%;
  font-family: var(--primary-font);
  color: var(--primary-text-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

button {
  cursor: pointer;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

// .wrapper {
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
// }
