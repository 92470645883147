@use "../utils/mixins" as m;

.btn {
  z-index: 1;
  position: relative;
  overflow: hidden;

  color: var(--primary-text-color);
  background-color: var(--second-back-color);

  border-radius: 10px;
  @include m.transition(transform);

  &::after {
    content: "";
    z-index: -1;
    background-color: hsla(0, 0%, 100%, 0.2);
    position: absolute;
    top: -50%;
    bottom: -50%;
    width: 1.25em;
    transform: translate3d(-825%, 0, 0) rotate(35deg);
  }

  &:hover::after,
  &:focus::after {
    transition: transform 0.45s ease-in-out;
    transform: translate3d(450%, 0, 0) rotate(35deg);
  }

  &:hover,
  &:focus {
    transform: scale(1.05);
  }
}
