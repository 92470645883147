@use "../utils/mixins" as m;

.header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767.98px) {
  .lock {
    overflow: hidden;
  }

  .header-menu {
    order: 2;
  }
  .nav {
    position: fixed;
    z-index: 4;
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    overflow: auto;

    padding: 140px 30px 30px 30px;
    display: flex;
    justify-content: center;

    @include m.transition(left);
    background: var(--back-gradient);

    &.active {
      left: 0;
    }
    // &::before {
    //   content: "";
    //   position: fixed;
    //   width: 100%;
    //   top: 0;
    //   left: 0;
    //   height: 115px;
    //   // background: linear-gradient(
    //   //   324deg,
    //   //   var(--hover-color),
    //   //   var(--second-back-color)
    //   // );
    // }
  }

  .nav-list-item:not(:last-child) {
    margin-bottom: 20px;
  }

  .nav-list-link {
    @include m.font(24px, 700, 28px);
    letter-spacing: 3px;
    color: var(--second-text-color);
  }
}

@media screen and (min-width: 768px) {
  .nav {
    display: block;
  }

  .nav-list {
    display: flex;
    gap: 2rem;
  }

  .nav-list-link {
    position: relative;

    @include m.font(18px, 700, 22px);
    color: var(--second-text-color);

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -2px;
      width: 100%;
      height: 3px;
      background-color: var(--hover-color);
      transform-origin: center;
      transform: translate(-50%, 0) scaleX(0);
      @include m.transition(transform);
    }

    &:hover::before,
    &:focus::before {
      transform: translate(-50%, 0) scaleX(1);
    }
  }
}

@media screen and (min-width: 991px) {
  .nav-list {
    gap: 3rem;
  }
}

//github icon
.github-corner-icon {
  fill: #000;
  color: #fff;
  position: absolute;
  top: 0;
  border: 0;
  left: 0;
  transform: scale(-1, 1);
  z-index: 9999;
  opacity: 0.85;
}
.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}
@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}
@media (max-width: 991px) {
  .github-corner {
    display: none;
  }
}
