@use "../utils/mixins" as m;

.scroll-top {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;

  padding: 0;

  line-height: 0;
  color: var(--hover-color);
  background-color: var(--back-color);
  border-radius: 50%;
  border: none;
  @include m.transition((color, background-color, opacity));

  cursor: pointer;
  opacity: 0;

  &:hover {
    color: var(--hover-color);
    background-color: var(--second-back-color);
  }
}

.scroll-top_active {
  opacity: 1;
}

.scroll-top__icon {
  width: 50px;
  height: 50px;

  fill: currentColor;
}
