.switcher {
  position: relative;
  z-index: 5;
  margin: 0;
  padding: 2px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border: none;
}

.switcher__radio {
  appearance: none;
  width: 32px;
  height: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  transition: filter 0.1s ease-in-out;
  opacity: 0.8;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}
.switcher__radio_light {
  background-image: url(../images/icons/light.svg);
}
.switcher__radio_auto {
  background-size: 18px;
  background-image: url(../images/icons/default.svg);
}
.switcher__radio_dark {
  background-image: url(../images/icons/dark.svg);
}
.switcher__status {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 20%);
  border-radius: 18px;
  background-image: url(../images/icons/status.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 32px;
  opacity: 0.7;
  transition: background-position 0.1s ease-in-out;
}

.switcher__radio:focus-visible ~ .switcher__status {
  box-shadow: 0 0 0 2px black;
}

.switcher__radio_light:checked ~ .switcher__status {
  background-position: left 2px center;
}

.switcher__radio_autot:checked ~ .switcher__status {
  background-position: center center;
}

.switcher__radio_dark:checked ~ .switcher__status {
  background-position: right 2px center;
}
