@use "../utils/mixins" as m;

.projects {
  background-color: var(--default-bg-color);
}

.projects-list-item {
  max-width: 480px;
  overflow: hidden;
  border-radius: 10px;
  background-color: var(--back-color);
  box-shadow: 0 4px 6px rgb(0 0 0 / 30%);
  @include m.transition(all);

  &:hover {
    box-shadow: 0 10px 20px rgb(0 0 0 / 70%);
    transform: scale(1.01);
  }
}

.projects-list-item:not(:last-child) {
  margin-bottom: 50px;
}

.project {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.project-img {
  aspect-ratio: 1.6;
}

.project-desc-wrap {
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
}

.project-title {
  margin-bottom: 1rem;
  @include m.font(18px, 600, 25px);
  text-align: center;
}

.project-description {
  margin-bottom: 0.8rem;
  @include m.font(16px, 400, 22px);
}

.project-stack {
  margin-bottom: 1rem;
  @include m.font(16px, 400, 22px);

  & span {
    font-weight: 600;
  }
}

.project-list {
  margin-top: auto;
  display: flex;
  justify-content: center;
  column-gap: 10px;
}

.project-list-item {
  min-width: 85px;
}

.project-link {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.6rem 0.4rem;

  @include m.font(10px, 400, 12px);

  & svg {
    fill: currentColor;
    margin-right: 0.3rem;
  }
}

@media screen and (min-width: 412px) {
  .project-list-item {
    min-width: 135px;
  }

  .project-link {
    font-size: 0.75rem;
  }
}

@media screen and (min-width: 480px) {
  .projects-list {
    display: grid;
    column-gap: 30px;
    row-gap: 50px;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-auto-rows: 1fr;
  }

  .projects-list-item:not(:last-child) {
    margin-bottom: 0;
  }

  .project-link {
    padding: 0.8rem 0.6rem;
    font-size: 1rem;
  }
}
// .project-img-wrap {
//   position: relative;
//   padding-bottom: 60%;
// }

// .project-img {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
// }
