//Vendors
@use "./vendors/_modern-normalize.css";

//Utils
@use "./utils/variables";
@use "./utils/visually-hidden";
@use "./utils/mixins";
@use "./utils/animation";

//Base
@use "./base/common";

//Components
@use "./components/spinner";
@use "./components/section";
@use "./components/container";
@use "./components/logo";
@use "./components/switcher";
@use "./components/mouse";
@use "./components/title";
@use "./components/menu-button";
@use "./components/buttons";
@use "./components/social-links";
@use "./components/scroll-to-top";

//Layout
@use "./layout/header";
@use "./layout/hero";
@use "./layout/about";
@use "./layout/skills";
@use "./layout/projects";
@use "./layout/contact";
@use "./layout/footer";
