:root {
  // Colors
  // --primary-text-color: #333333;
  // --second-text-color: #fff;
  // --back-color: #fffaf2;
  // --second-back-color: #f5d6ab;
  // --back-gradient: linear-gradient(
  //   324deg,
  //   var(--hover-color),
  //   var(--second-back-color)
  // );
  // --hover-color: #c66c49;

  // Typography
  --primary-font: "Libre Franklin", sans-serif;
}
