@use "../utils/mixins" as m;

.about {
  background-color: var(--default-bg-color);
}

.about-description {
  @include m.font(18px, 400, 30px);

  &:not(:last-child) {
    margin-bottom: 1.89em;
  }
}
