.section {
  padding-top: 64px;
  padding-bottom: 80px;
}

@media screen and (min-width: 991px) {
  .section {
    padding-top: 90px;
    padding-bottom: 140px;
  }
}
