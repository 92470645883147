@use "../utils/mixins" as m;

.hero {
  min-height: 100vh;
  padding-top: 120px;
  padding-bottom: 120px;
  color: var(--second-text-color);
  background: var(--back-gradient);
}

.hero-flex {
  padding-top: 2rem;
}

.hero-title {
  margin-bottom: 0.6em;
  @include m.font(60px, 400, 74px);
}

.hero-subtitle {
  @include m.font(24px, 400, 29px);
}

.hero-flex-right {
  display: none;
}

@media screen and (min-width: 650px) {
  .hero-title {
    font-size: 4rem;
  }
}

@media screen and (min-width: 768px) {
  .hero-flex {
    display: flex;
    align-items: center;
  }
  .hero-flex-left {
    flex: 0 1 60%;
  }
  .hero-flex-right {
    display: block;
    flex: 0 1 40%;
  }
}
