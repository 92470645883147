%menu {
  position: absolute;
  display: inline-block;
  left: 0;
  width: 100%;
  height: 13%;
  background-color: var(--second-text-color);
  transition: all 0.3s ease;
}

.btn-menu {
  width: 30px;
  height: 18px;
  display: block;
  position: relative;
  z-index: 5;
  padding: 0;
  border: 0;
  line-height: 0;

  background-color: transparent;

  &::before,
  &::after {
    @extend %menu;
    content: "";
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
}

.btn-menu__icon {
  @extend %menu;
  top: 50%;
  transform: translate(0, -50%) scale(1);
}

.btn-menu.active {
  .btn-menu__icon {
    transform: scale(0);
  }
}
.btn-menu.active::before {
  top: 50%;
  transform: rotate(45deg) translate(0, -50%);
}
.btn-menu.active::after {
  bottom: 50%;
  transform: rotate(-45deg) translate(0, 50%);
}

@media screen and (min-width: 768px) {
  .btn-menu {
    display: none;
  }
}
